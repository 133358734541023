import React, { useEffect, useState } from 'react';
import PFVideo from '../components/PFVideo';
import SEO from '../components/SEO';
import ProductsContainer from '../components/ProductsContainer';
import { graphql } from 'gatsby';
import track from 'we-sdk/utils/track';
import storage from 'we-sdk/storage';
import getLogger from 'we-sdk/utils/getLogger';
import pfManager from '../lib/PFManager';
import reportNewView from 'we-sdk/utils/reportNewView';

let ekoPlayer;
export let ageVar = '0-3 Months';
export let activityVar = 'Newborn';
export let budgetVar = 'low';

const log = getLogger('PFVideoPage');

/**
 * Video page template.
 * @export
 * @param {object} props
 * @param {object} props.data - The result from the graphql query that is exported in this module.
 * @param {object} props.pageContext - the page context that is passed from the gatsby create page API in gatstby-node.js (see more https://www.gatsbyjs.org/docs/creating-and-modifying-pages/)
 * @returns
 */
export default function PFVideoPage({ data, pageContext }) {
    const projectData = data.allContentfulProject.edges[0].node;

    const [showResults, setShowResults] = useState(false);
    const [chosenProducts, setChosenProducts] = useState(null);
    const [ekoPlayerRef, setEkoPlayerRef] = useState(null);

    useEffect(() => {
        log.debug(`Loaded with eko ID ${pageContext.ekoId}`);

        // IMPORTANT - THIS IS AN EXAMPLE TRACK CALL.
        // ACTUAL ANALYTICS SPEC/REQUIREMENTS WILL BE PROVIDED LATER
        track('root.pageload', {
            ekoId: pageContext.ekoId
        });

        pfManager.load();
    }, [pageContext]);

    useEffect(() => {
        if (!ekoPlayerRef) {
            return;
        }

        ekoPlayer = pfManager.setEkoPlayerRef(ekoPlayerRef);
    }, [ekoPlayerRef])

    const onNodeStart = async (node) => {
        log.debug(`[nodestart] [${node.id}]`, node);

        if (node.data && node.data.studio && node.data.studio.startOverNode) {

            try {
                const storageStore = await storage.get('recommendationStorage');
                storageStore.removeItem('resultNodeId');
                storageStore.removeItem('recommendations');

            } catch (err) {
                console.error('Unable to resolve storage', err);
            }

        }

        if (node.data && node.data.studio && node.data.studio.resultsNode) {

            setChosenProducts(pfManager.getTopXProducts(3, node));

            setShowResults(true);
        } else {
            setShowResults(false);
        }
    }

    const onInteractionsReport = (btnId, data, interaction) => {
        if(interaction && interaction.elementid === 'restartbutton') {
            reportNewView();
        }

        // if interaction does not include scoring metrics, bail out of scoring process
        if (!data.metadata) return;

        if (data) {
            const {
                metadata: {
                    studio: {
                        varToUpdate,
                        updateValue
                    }
                } = { metadata: { studio: {} } }
            } = data;

            if (varToUpdate && updateValue) {
                log.debug(`Updating "${varToUpdate}" to "${updateValue}"`);
                // Update search criteria to score all products accordingly
                pfManager.updateSearchCriteria(varToUpdate, updateValue);
            }
        }
    }

    const onVariablesUpdate = (name, oldValue, newValue) => {
        if (name == 'age') {
            ageVar = newValue;
        }
        if (name == 'activity') {
            activityVar = newValue;
        }
        if (name == 'budget') {
            budgetVar = newValue;
        }
    }

    return (
        <>
            <SEO title={projectData.seoTitle} description={projectData.seoDescription} />
            <PFVideo
                id={pageContext.ekoId}

                // Listen to eko player events
                // IMPORTANT NOTE - DO NOT READ/CONSUME REACT STATE IN HANDLERS
                // INSTEAD, SET STATE AND HANDLE READING IN useEffetct() hook.
                events={{
                    nodestart: onNodeStart,
                    'interactions.report': onInteractionsReport,
                    'variables.update': onVariablesUpdate,
                }}

                onPlayerInit={setEkoPlayerRef}
            />
            {/* commented out to have products displayed in video layer
            {
                showResults && chosenProducts && chosenProducts.length ?
                    <ProductsContainer products={chosenProducts}, ekoId={pageContext.ekoId} /> :
                    null
            } */}
        </>
    );
};

// This query will be running at build time and its result will be passed to the toy page template through the data prop.
export const query = graphql`
query($ekoId: String!) {
    allContentfulProject(filter: {ekoId: {eq: $ekoId}}) {
        edges {
            node {
                ekoId,
                seoTitle,
                seoDescription
            }
        }
    }
}
`
