/*
 * Demo implementation for a possible Product Finder manager which exposes a few methods in order to allow
 * loading and scoring of products.
 * NOTE THAT THIS IS JUST AN EXAMPLE IMPLEMENTATION
 */

///////////////////////////////////////////////////////////
// Import Dependencies
///////////////////////////////////////////////////////////

import { createClient } from 'contentful';
import products from 'we-sdk/products';
import getLogger from 'we-sdk/utils/getLogger';
import storage from 'we-sdk/storage';
import track from 'we-sdk/utils/track';
import { ageVar, activityVar, budgetVar } from '../templates/PFVideoPage';

///////////////////////////////////////////////////////////
// Members
///////////////////////////////////////////////////////////

// A map of criteria to scoring object
const SCORING_MAP = {
    age: {
        match: +0,
        noMatch: -0
    },
    activity: {
        match: +0,
        noMatch: -0
    },
    budget: {
        match: +0,
        noMatch: -0
    }
};

// Map of criteria to custom matching functions
const MATCHERS_MAP = {
    budget: (product, value) => {
        return (
            value === 'low' &&
            product.tags.includes('<$25')
        ) || (
                value !== 'low' &&
                !product.tags.includes('<$25')
            );
    }
};
const DEFAULT_MATCH_POINTS = +1;
const DEFAULT_NO_MATCH_POINTS = -3;

const log = getLogger('PFManager');
let searchCriteria = {};
let scoredOn = {};
let allProducts = [];
let ekoPlayerRef;

///////////////////////////////////////////////////////////
// Private Methods
///////////////////////////////////////////////////////////

async function loadContentfulProducts() {
    const contentfulClient = createClient({
        accessToken: process.env.GATSBY_PRODUCTFINDER_CONTENTFUL_ACCESS_TOKEN,
        space: process.env.GATSBY_PRODUCTFINDER_CONTENTFUL_SPACE_ID
    });

    // Query CMS for all entries of type "product"
    const limit = 1000;
    let skip = 0;
    let resArr = [];
    let result;

    do {
        result = await contentfulClient.getEntries({
            content_type: 'product',
            skip: skip,
            limit: limit,
            order: 'sys.createdAt'
        });
        skip += result.items.length;
        resArr = resArr.concat(result.items.map(item => item.fields));
    } while (resArr.length < result.total);

    return resArr;
};

function scoreAllProducts(value) {
    if (!allProducts.length) {
        return;
    }

    Object.keys(searchCriteria)
        .forEach(criteria => {
            // Only score products on each criteria once
            if (scoredOn[criteria] === searchCriteria[criteria]) {
                return;
            }

            // Figure out how many points a match is worth, and how many points a non-match is worth
            const matchPoints = SCORING_MAP[criteria] ? SCORING_MAP[criteria].match : DEFAULT_MATCH_POINTS;
            const noMatchPoints = SCORING_MAP[criteria] ? SCORING_MAP[criteria].noMatch : DEFAULT_NO_MATCH_POINTS;

            // Iterate over all products to apply their new score
            // if value from button is positive, add value, if negative subtract value
            if (Math.sign(value) == 1) {
                allProducts.forEach(product => {
                    product.score += doesProductMatchCritera(product, criteria, searchCriteria[criteria]) ?
                        matchPoints :
                        0;
                });
            } else {
                allProducts.forEach(product => {
                    product.score += doesProductMatchCritera(product, criteria, searchCriteria[criteria]) ?
                        noMatchPoints :
                        0;
                });
            }

            // Mark this criteria as scored, so that we won't score again
            scoredOn[criteria] = searchCriteria[criteria];
        });
}

function getSortedProducts() {
    return allProducts
        .sort((p1, p2) => p2.score - p1.score);
}

function doesProductMatchCritera(product, criteria, value) {
    if (MATCHERS_MAP[criteria]) {
        return MATCHERS_MAP[criteria](product, value);
    }

    return product.tags.includes(criteria);
}

///////////////////////////////////////////////////////////
// Public Methods
///////////////////////////////////////////////////////////

/**
 * Loads all the products from Contentful and Walmart API
 * and combines them into a single array containing all the data for each product.
 * @async
 */
async function load() {
    allProducts = await loadContentfulProducts();

    // Load an array of Walmart products data
    let walmartProductsDataArr = await products.getList.apply(null, allProducts.map(p => p.walmartProductId));

    // Convert the array to a map
    let productDataMap = {};
    walmartProductsDataArr.forEach(product => {
        productDataMap[product.itemId] = product;
    });

    // Attach Walmart product data to our allProducts array
    allProducts.forEach(product => {
        // Init score within product object
        product.score = 0;

        if (!productDataMap[product.walmartProductId]) {
            log.error(`Walmart product data not found for item "${product.walmartProductId}":`, product);
            return;
        }

        // Attach walmart data to product object
        product.data = productDataMap[product.walmartProductId];
    });

    allProducts.forEach(async (product, index) => {
        if (product.data.salePrice >= 25) {
            product.tags.push('$25-$50+');
        } else product.tags.push('<$25');

        if (product.data.stock != 'Available') {
            console.log(`Walmart reports out of stock "${product.data.name}"`);

            // if product is out of stock, attempt to replace product URL with an available variant
            // if no variants are in stock, no change is made
            if (product.data.variants) {
                let walmartProductsVariantsDataArr = await products.getList.apply(null, product.data.variants.map(p => p));
                walmartProductsVariantsDataArr.forEach(p => {
                    if (p.stock == 'Available') {
                        let replacementUrl = p.productTrackingUrl;
                        product.data.productTrackingUrl = replacementUrl;
                    }
                });
            }

            // allProducts.splice(index, 1);
            return;
        }
    });

    // on video layer restart, reset points and recommendation storage
    // ekoPlayerRef.on('giftshop.restart', function () {
    //     console.log('This is an handler function to my event!');
    // });

    log.debug('Loaded all products from Contentful & Walmart:', allProducts);
}

export async function listenForPluginInit() {

    try {
        const storageStore = await storage.get('recommendationStorage');
        const resultNodeId = storageStore.getItem('resultNodeId');
        const recommendations = storageStore.getItem('recommendations');

        if ((resultNodeId && resultNodeId.length) && (recommendations && recommendations.length)) {
            ekoPlayerRef.invoke('seek', resultNodeId);
            ekoPlayerRef.invoke('variables.setValue', 'recommendations', recommendations);
        }

    } catch (err) {
        console.error('Unable to resolve storage', err);
    }
}

export async function clearRecommendationStorage() {
    try {
        allProducts.forEach(product => {
            product.score = 0;
        });
        const storageStore = await storage.get('recommendationStorage');
        storageStore.clear();
    } catch (err) {
        console.error('Unable to resolve storage', err);
    }
}

/**
 * Update our search criteria object and adjust scores for all products accordingly.
 *
 * @param {string} criteria - The name of the search criteria, i.e. age, budget etc.
 * @param {string} value - The string value (tag) for this criteria.
 */
function updateSearchCriteria(criteria, value) {
    // Add our value to the searchCriteria state object
    searchCriteria[criteria] = value;

    // Update the score for all products
    scoreAllProducts(value);
    ekoPlayerRef.invoke('variables.setValue', 'scoredProducts', getSortedProducts());
    log.debug('Update score for all products:', getSortedProducts());
}

/**
 * Clear the search criteria object and zero out all products' scores.
 */
function clearSearchCriteria() {
    searchCriteria = {};
    scoredOn = {};
    allProducts.forEach(p => p.score = 0);
}

/**
 * Get the top products that match our search criteria.
 * @param {number} count - Count of products to return.
 * @returns {Product[]} An array of product objects.
 */
async function getTopXProducts(count, node) {
    let recommendations;

    try {
        const storageStore = await storage.get('recommendationStorage');
        const resultNodeId = storageStore.getItem('resultNodeId');
        recommendations = storageStore.getItem('recommendations');

        if (resultNodeId && recommendations) {

            ekoPlayerRef.invoke('variables.setValue', 'recommendations', recommendations);
        } else {

            // Clone the array
            recommendations = [...getSortedProducts()];

            if (!(recommendations && recommendations.length)) return null;
            let budgetTag = '<$25';
            if (budgetVar == 'high') budgetTag = '$25-$50+';

            const filteredRecommendations = recommendations.filter(product => product.tags.includes(ageVar) && product.tags.includes(budgetTag) && product.tags.includes(activityVar));
            console.log('RECOMMENDATIONS AFTER FILTER ', filteredRecommendations);
            let topRecommendations = filteredRecommendations.slice(0, count);

            storageStore.setItem('recommendations', topRecommendations);
            storageStore.setItem('resultNodeId', node.id);
            ekoPlayerRef.invoke('variables.setValue', 'recommendations', filteredRecommendations);

            topRecommendations.forEach(recommendation => {
                const { walmartProductId } = recommendation;
                track('fetch.product', { walmartProductId });
            });
        }

    } catch (err) {
        console.error('getTopXProducts', 'Unable to resolve storage', err, recommendations);
    }

    return recommendations;
}

function setEkoPlayerRef(value) {
    ekoPlayerRef = value;

    // ekoPlayerRef is an instance of:
    // https://github.com/EkoLabs/eko-js-sdk
    // Which exposes useful API such as:
    // ekoPlayerRef.on('eventname', () => { ... });
    // ekoPlayerRef.invoke('audio.play', 'ping');
    //
    // You can write some init code here to listen to events or invoke player functions.
    log.debug('setEkoPlayerRef', value);
    return value;
}

///////////////////////////////////////////////////////////
// Exports
///////////////////////////////////////////////////////////

export default {
    load,
    setEkoPlayerRef,
    updateSearchCriteria,
    clearSearchCriteria,
    getTopXProducts,
};
