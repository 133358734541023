import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ProductThumbnail from './ProductThumbnail';
import '../styles/scss/components/productsContainer.scss';


function ProductsContainer({ products, ekoId }) {
    return (
        <>
            <div className='products-container-fadeout-bg' />
            <div className='products-container'>
                {products.map(product => {
                    return <ProductThumbnail key={product.walmartProductId} product={product} ekoId={ekoId} />
                })}
            </div>
        </>
    );
};

ProductsContainer.propTypes = {
    products: PropTypes.arrayOf(
        PropTypes.shape({
            walmartProductId: PropTypes.string.isRequired,
            productName: PropTypes.string.isRequired,
            data: PropTypes.object.isRequired,
        })
    ).isRequired
}

export default ProductsContainer;
