import React from 'react';
import EkoVideo from 'we-sdk/components/WeSdkEkoVideo';
import PFVideoLoadingCover from './PFVideoLoadingCover';
import { openUrl } from '../lib/utils';
import { listenForPluginInit, clearRecommendationStorage } from '../lib/PFManager';

export default function ProductFinderVideo(props) {
    return (
        <EkoVideo
            {...props}
            params={{ ...props.params, clearcheckpoints: true }}
            events={{
                ...props.events,
                'plugininitvariables': () => listenForPluginInit(),
                'control.replay': () => clearRecommendationStorage(),
                // Listen to the 'urls.intent' event,
                // which would prevent the player frame from opening URLs on its own,
                // and instead report a 'urls.intent' event to parent frame.
                // https://developer.eko.com/api/urls.html#module_urls+event_urls.intent
                'urls.intent': ({ url }) => {
                    let urlParams = new URLSearchParams(url);
                    openUrl(url, { ekoId: props.id, walmartId: urlParams.get('prodsku'), appId: 'productfinder-app' });
                }
            }}
            loadingCover={PFVideoLoadingCover}
            expandToFillContainer={true}
       />
    );
}
