import '../styles/scss/components/pfVideoLoadingCover.scss';
import walmartLogo from '../images/WalmartSpark.svg';
import ekoLogo from '../images/eko_logo_white.svg';
import React from 'react';

export default function PFVideoLoadingCover() {
    return (
        <div className='loading-cover-container'>
            <img className='walmart-logo' src={walmartLogo} alt='Walmart' />
            <div className="progress-bar">
                <div className="progress-bar-value"></div>
            </div>
        </div>
    );
}
